<script setup>

const props = defineProps({ blok: Object })

import { computed } from "vue";
import { renderRichText } from "@storyblok/vue";

const articleContent = computed(() => renderRichText(props.blok));

</script>

<template>
  <v-container v-if="blok.content[0].content" fluid class="pb-0">
    <v-row>
      <v-col class="v-col-12 offset-0 v-col-sm-10 offset-sm-1 v-col-md-8 offset-md-2 v-col-lg-6 offset-lg-3 mt-0 pb-0">
        <div class="body-text" v-html="articleContent"></div>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
h2 {
  margin-bottom: 0.1em;
}
h3 {
  margin-bottom: 0.1em;
}
.body-text p {
  margin-bottom: 1em;
  line-height: 1.2em;
}
</style>
