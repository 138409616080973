<template>
  <div v-if="blok.background_video" style="padding:56.25% 0 0 0;position:relative" class="d-none d-sm-block hero-desktop">
    <iframe :src="'https://player.vimeo.com/video/' + blok.background_video + '?background=1&autoplay=1&loop=1'" responsive="true" frameborder="0" allow="autoplay; fullscreen" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe>
  </div>
  <div v-if="blok.background_video_mobile" style="padding:177.78% 0 0 0;position:relative" class="d-block d-sm-none hero-desktop">
    <iframe :src="'https://player.vimeo.com/video/' + blok.background_video_mobile + '?background=1&autoplay=1&loop=1'" responsive="true" frameborder="0" allow="autoplay; fullscreen" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe>
  </div>
<!--  <div v-else>-->
<!--    <v-img :src="blok.background_image.filename + '/m/filters:brightness(' + blok.background_brightness.value + '):blur(' + blok.background_blur.value + ')'" :class="fullWidthClasses">-->
<!--      <div v-if="blok.headline_over_image" class="pa-12 over-image-container" :style="blok.headline_colour ? 'color:' + blok.headline_colour.color : 'color:' + 'white'">-->
<!--        <h1 :class="'h1-over-image font-' + blok.headline_font_face + ' font-size-' + blok.headline_font_size" :style="'color:' + blok.headline_colour.color">-->
<!--          <span style="white-space: pre-line">{{ blok.headline }}</span>-->
<!--        </h1>-->
<!--      </div>-->
<!--    </v-img>-->
<!--  </div>-->

  <div v-else>
    <div class="d-block d-sm-none">
      <v-img
          v-if="blok.background_image_mobile && blok.background_image_mobile.filename"
          aspect-ratio="3/4"
          lazy-src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
          :src="blok.background_image_mobile.filename + '/m/600x800'"
          :class="fullWidthClasses"
          :alt="blok.background_image_mobile.alt"
      >
      </v-img>
      <v-img
          v-else
          aspect-ratio="3/4"
          lazy-src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
          :src="blok.background_image.filename + '/m/600x800/filters:focal('+ blok.background_image.focus +')'"
          :class="fullWidthClasses"
          :alt="blok.background_image.alt"
      >
      </v-img>
    </div>
    <div class="d-none d-sm-block">
      <v-img
          aspect-ratio="16/9"
          lazy-src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
          :srcset="blok.background_image.filename + '/m/1280x720/filters:brightness(' + blok.background_brightness.value + '):blur(' + blok.background_blur.value + ') 1280w,' + blok.background_image.filename + '/m/1920x1080/filters:brightness(' + blok.background_brightness.value + '):blur(' + blok.background_blur.value + ') 1920w,' + blok.background_image.filename + '/m/2560x1440/filters:brightness(' + blok.background_brightness.value + '):blur(' + blok.background_blur.value + ') 2560w'"
          sizes="(min-width: 960px) 1280px, (min-width: 1280px) 1920px, 2560px"
          :src="blok.background_image.filename + '/m/960x540/filters:brightness(' + blok.background_brightness.value + '):blur(' + blok.background_blur.value + ')'"
          :class="fullWidthClasses"
          :alt="blok.background_image.alt"
      >
        <div :class="flexClasses">
          <div v-if="blok.headline_over_image" class="pa-12 over-image-container" :style="'color:' + blok.headline_colour.color + ';width:' + blok.headline_width + '%'">
            <h1 :class="'h1-over-image font-' + blok.headline_font_face + ' font-size-' + blok.headline_font_size" :style="'color:' + blok.headline_colour.color">
              <span style="white-space: pre-line">{{ name }}</span>
            </h1>
          </div>
        </div>
      </v-img>
    </div>


  </div>

  <v-container fluid class="standfirst-container pt-7 pt-md-14">

    <!--    <v-row class="d-block d-sm-none">-->
<!--      <h2 :class="'subtitle-xs text-center font-' + blok.headline_font_face + ' text-' + blok.text_color">{{ blok.subheadline }}</h2>-->
<!--    </v-row>-->
    <v-row>
      <v-col v-if="!blok.headline_over_image && !blok.hide_headline" class="d-none d-sm-block v-col-12 v-col-sm-10 offset-sm-1 pt-6 pb-0">
        <h1 class="h1-under-image">{{ name }}</h1>
      </v-col>
      <v-col v-if="!blok.hide_headline_mobile" class="d-block d-sm-none v-col-12 v-col-sm-10 offset-sm-1 pt-6 pb-0">
        <h1 class="h1-under-image">{{ name }}</h1>
      </v-col>
      <v-col class="v-col-12 v-col-sm-10 offset-sm-1 pb-0 reporter">
        <p class="lead-caption mb-8">
          <authors :reporters="reporters" :opEd="opEd" />
          <VerticalTag v-if="vertical" :vertical="vertical" :opEd="opEd"/><br class="d-block d-sm-none"><span class="date">{{ moment(first_published_at).format("MMMM D YYYY") }}</span><br><span class="credit" v-if="blok.background_image.source">{{ blok.background_image.source }}</span></p>

      </v-col>
    </v-row>
    <v-row class="pt-0 pb-0 pt-sm-2 pb-sm-2 mt-0">
      <v-col class="v-col-12 v-col-sm-10 offset-sm-1 pt-0"><p class="standfirst">{{ blok.standfirst }}</p></v-col>
    </v-row>
  </v-container>


</template>

<script setup>
import router from "@/router";

const props = defineProps({ blok: Object, reporters: Object, first_published_at: String, vertical: String, tags: Array, name: String, opEd: Boolean })

import VerticalTag from "@/components/VerticalTag.vue"
import Authors from "@/components/Authors.vue"
import moment from "moment"

import { computed } from 'vue'
import { useTheme } from "vuetify";
const theme = useTheme()

const tagColour = (vertical) => {
  if (vertical === 'finance') {
    return theme.global.current.value.colors.financeHighlight
  }
  else if (vertical === 'culture') {
    return theme.global.current.value.colors.cultureHighlight
  }
  else if (vertical === 'business') {
    return theme.global.current.value.colors.businessHighlight
  }
}

const search = (tag) => {
  router.push({name: 'search-tag', params: {tag: tag}})
}

const flexClasses = computed(() => {

  if (props.blok.headline_position === 'centre_middle' || props.blok.headline_position === 'centre_top' || props.blok.headline_position === 'centre_bottom') {
    return 'd-flex justify-center'
  }
  return ''
})

const fullWidthClasses = computed(() => {
  if (props.blok.headline_position === 'centre_middle') {
    return 'text-center align-center header-image'
  }
  else if (props.blok.headline_position === 'centre_top') {
    return 'text-center align-start header-image'
  }
  else if (props.blok.headline_position === 'centre_bottom') {
    return 'text-center align-end header-image'
  }
  if (props.blok.headline_position === 'centre_left') {
    return 'text-left align-center header-image'
  }
  else if (props.blok.headline_position === 'centre_right') {
    return 'text-right align-center header-image half-right'
  }
  else if (props.blok.headline_position === 'top_left') {
    return 'text-left align-start header-image'
  }
  else if (props.blok.headline_position === 'top_right') {
    return 'text-right half-right align-start header-image'
  }
  else if (props.blok.headline_position === 'bottom_left') {
    return 'text-left align-end header-image'
  }
  else if (props.blok.headline_position === 'bottom_right') {
    return 'text-right half-right align-end header-image'
  }
  else {
    return 'centre-bottom align-center header-image'
  }
})


</script>

<style scoped lang="scss">

.vertical-business {
  .standfirst-container {
    p, .author, .h1-under-image {
      color: rgb(var(--v-theme-businessText));
    }
  }
}
.vertical-finance {
  .standfirst-container {
    p, .author, .h1-under-image {
      color: rgb(var(--v-theme-financeText));
    }
  }
}
.vertical-culture {
  .standfirst-container {
    p, .author, .h1-under-image {
      color: rgb(var(--v-theme-cultureText));
    }
  }
}
.vertical- {
  .standfirst-container {
    p, .author, .h1-under-image {
      color: white;
    }
  }
}

.subtitle-xs {
  font-size: 1.4em;
}
.h1-over-image {
  line-height: 1em;
}
.h1-under-image {
  font-family: "GroteskRegular", serif !important;
  font-weight: normal;
  font-size: 4em;
  line-height: 1em;
  margin-top: 6px;
}

.vertical-business .standfirst-container {
  background-color: rgb(var(--v-theme-business));
}
.vertical-finance .standfirst-container {
  background-color: rgb(var(--v-theme-finance));
}
.vertical-culture .standfirst-container {
  background-color: rgb(var(--v-theme-culture));
}
.vertical- .standfirst-container {
  background-color: rgb(var(--v-theme-surface));
}

.standfirst {
  font-family: "GroteskScreen", serif !important;
  padding-bottom: 40px;
  line-height: 1.1em;
}
.lead-caption {
  font-family: "GroteskMonoRegular", sans-serif !important;
  text-transform: uppercase;
}

@media only screen and (max-width: 599px) {
  .h1-over-image {
    font-size: 2em;
  }
  .h1-under-image {
    font-size: 2.1em;
    line-height: 1.2em;
  }
  .standfirst {
    font-size: 1.5em;
    line-height: 1.3em;
    padding-bottom: 23px;
  }
  .lead-caption {
    margin-top: 10px
  }
}

@media only screen and (min-width: 600px) {
  .standfirst {
    font-size: 2.5em;
    line-height: 1.2em;
  }
  .h1-over-image {
    font-size: 3em;
  }
  .h1-under-image {
    font-size: 3.5em;
    line-height: 1.1em;
  }
  .lead-caption {
    margin-top: 16px
  }
}

@media only screen and (min-width: 960px) {
  .h1-over-image {
    font-size: 4em;
  }
  .h1-under-image {
    font-size: 3.5em;
    line-height: 1.1em;
  }
}

@media only screen and (min-width: 1280px) {
  .h1-over-image {
    font-size: 6em;
  }
}

@media only screen and (min-width: 1920px) {
  .h1-over-image {
    font-size: 8em;
  }
}

.half-right .over-image-container {
  float: right;
}

</style>
