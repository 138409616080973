<template>
  <div class="d-block d-sm-none">

      <router-link :to="{ name: 'article', params: {slug: blok.slug}}">
        <v-img
            v-if="backgroundImageMobile.filename"
            aspect-ratio="3/4"
            lazy-src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
            :src="backgroundImageMobile.filename + '/m/600x800/filters:focal('+ backgroundImageMobile.focus +')'"
            :alt="backgroundImageMobile.alt"
        >
        </v-img>
        <v-container class="pt-0 pb-0">
          <h1 class="mobile"><span>{{ blok.name }}</span></h1>
        </v-container>
      </router-link>
        <v-container class="pt-0 pb-11">
          <authors :reporters="reporters" />
          <v-btn v-if="vertical" variant="flat" density="compact" color="#4F4F4F" class="tag text-uppercase"><span v-if="blok.op_ed">PERSPECTIVES</span><span v-else>FUTURE OF {{ vertical }}</span></v-btn>
    </v-container>

  </div>
  <div class="d-none d-sm-block">
    <router-link :to="{ name: 'article', params: {slug: blok.slug}}">
    <v-img
        aspect-ratio="16/9"
        :srcset="srcSet"
        sizes="(min-width: 960px) 1280px, (min-width: 1280px) 1920px, 2560px"
        :src="src"
        lazy-src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
        :alt="backgroundImage.alt"
        :class="fullWidthClasses"
    >
      <div>
        <v-container fluid>
          <v-row>
            <v-col :class="'v-col-12 offset-0 ' + flexClasses">
              <div class="over-image-container" :style="'width:' + headlineWidth + '%'">

                <h1 :class="'h1-over-image font-' + h1Font" :style="'color:' + h1Colour"><span style="white-space: pre-line">{{ blok.name }}</span></h1>

                <span v-if="reporters && reporters.length > 0" class="author d-inline-block mr-4" :style="'color:' + h1Colour">
                  <span v-for="(reporter, key) in reporters" :key="reporter._uid">
                    {{ reporter.name }}<span v-if="reporter.slug && key+1 < reporters.length"> &amp; </span>
                  </span>
                </span>

                <v-btn v-if="vertical" variant="flat" density="compact" color="#4F4F4F" class="tag text-uppercase"><span v-if="blok.content.op_ed">PERSPECTIVES</span><span v-else>FUTURE OF {{ vertical }}</span></v-btn>

              </div>
            </v-col>
          </v-row>
        </v-container>


      </div>

    </v-img>
    </router-link>
  </div>
</template>

<script setup>
const props = defineProps({ blok: Object, header: Object, reporters: Object, vertical: String, backgroundImage: Object, backgroundImageMobile: Object, standfirstWidth: Number, headlineWidth: Number, headlineColour: Object, headlinePosition: String, headlineFontFace: String, backgroundBrightness: Object, backgroundBlur: Object })

import {computed} from "vue";
import { useTheme } from "vuetify";
import Authors from "@/components/Authors.vue"
const theme = useTheme()

const h1Colour = computed( () => {

  if (props.headlineColour) {
    return props.headlineColour.color
  }
  return props.header.headline_colour.color
})

const h1Font = computed( () => {

  if (props.headlineFontFace) {
    return props.headlineFontFace
  }
  else {
    props.header.headline_font_face
  }

})

const srcSet = computed(() => {

  let image = props.header.background_image
  let brightness = props.header.background_brightness
  let blur = props.header.background_blur

  if (props.backgroundImage) {
    image = props.backgroundImage
  }
  if (props.backgroundBrightness) {
    brightness = props.backgroundBrightness
  }
  if (props.backgroundBlur) {
    blur = props.backgroundBlur
  }

  return image.filename + '/m/1280x0/filters:brightness(' + brightness.value + '):blur(' + blur.value + ') 1280w,' + image.filename + '/m/1920x0/filters:brightness(' + brightness.value + '):blur(' + blur.value + ') 1920w,' + image.filename + '/m/2560x0/filters:brightness(' + brightness.value + '):blur(' + blur.value + ') 2560w'
})

const src = computed( () => {

  let image = props.header.background_image
  let brightness = props.header.background_brightness
  let blur = props.header.background_blur

  if (props.backgroundImage) {
    image = props.backgroundImage
  }
  if (props.backgroundBrightness) {
    brightness = props.backgroundBrightness
  }
  if (props.blok.backgroundBlur) {
    blur = props.blok.backgroundBlur
  }

  return image.filename + '/m/960x0/filters:brightness(' + brightness.value + '):blur(' + blur.value + ')'
})

const alt = computed( () => {

  let image = props.header.background_image

  if (props.backgroundImage) {
    image = props.backgroundImage
  }

  return image.alt
})

const tagColour = (vertical) => {
  if (vertical === 'finance') {
    return theme.global.current.value.colors.financeHighlight
  }
  else if (vertical === 'culture') {
    return theme.global.current.value.colors.cultureHighlight
  }
  else if (vertical === 'business') {
    return theme.global.current.value.colors.businessHighlight
  }
}

const flexClasses = computed(() => {

  let position = props.blok.content.header[0].headline_position
  if (props.headlinePosition) {
    position = props.headlinePosition
  }

  if (position === 'centre_middle' || position === 'centre_top' || position === 'centre_bottom') {
    return 'd-flex justify-center'
  }
  return ''
})

const fullWidthClasses = computed(() => {

  let position = props.blok.content.header[0].headline_position
  if (props.headlinePosition) {
    position = props.headlinePosition
  }

  if (position === 'centre_middle') {
    return 'text-center align-center'
  }
  else if (position === 'centre_top') {
    return 'text-center align-start'
  }
  else if (position === 'centre_bottom') {
    return 'text-center align-end'
  }
  if (position === 'centre_left') {
    return 'text-left align-center'
  }
  else if (position === 'centre_right') {
    return 'text-right align-center half-right'
  }
  else if (position === 'top_left') {
    return 'text-left align-start'
  }
  else if (position === 'top_right') {
    return 'text-right half-right align-start'
  }
  else if (position === 'bottom_left') {
    return 'text-left align-end'
  }
  else if (position === 'bottom_right') {
    return 'text-right half-right align-end'
  }
  else {
    return 'centre-bottom align-center'
  }
})
</script>

<style scoped lang="scss">

.feature a:hover {
  text-decoration: none;
  h1 {
    text-decoration: underline !important;
    text-decoration-thickness: 5px !important;
  }
}

h1.mobile {
  font-size: 2.6em;
  line-height: 1em;
  margin: 45px 0 18px 0;
}
h2.mobile {
  font-size: 1.4em;
  line-height: 1.3em;
}

.h2-over-image {
  margin-top: 24px
}

.half-right .over-image-container {
  float: right;
  @media only screen and (min-width: 960px) {
    margin-right: 30px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.align-end .over-image-container {
  margin-bottom: 40px;
  @media only screen and (min-width: 960px) {
    margin-left: 30px
  }
}

@media only screen and (max-width: 599px) {
  .h1-over-image {
    font-size: 2em;
  }
  .h2-over-image {
    font-size: 1.6em;
  }
}

@media only screen and (min-width: 600px) {
  .h1-over-image {
    font-size: 3em;
    margin-bottom: 40px;
  }
  .h2-over-image {
    font-size: 2em;
  }
}

@media only screen and (min-width: 960px) {
  .h1-over-image {
    font-size: 4em;
  }
  .h2-over-image {
    font-size: 3em;
  }
}

@media only screen and (min-width: 1280px) {
  .h1-over-image {
    font-size: 6em;
  }
  .h2-over-image {
    font-size: 2em;
  }
}

@media only screen and (min-width: 1920px) {
  .h1-over-image {
    font-size: 8em;
  }
  .h2-over-image {
    font-size: 3em;
  }
}

</style>
