<template>
  <v-container fluid class="pt-0 pb-0 mt-6 mb-6">
    <v-row :class="rowClasses">
      <v-col :class="col1Classes">
        <double-image-image :image="blok.images[0].image" :fit="blok.image_fit" height="auto" />
        <p v-if="blok.images[0].image.title && blok.images[1].image.title" class="d-block d-md-none mt-5 pl-1 pr-1"><span class="copyright">{{ blok.images[0].image.title }}</span><span class="credit">{{ blok.images[0].image.source }}</span></p>
      </v-col>
      <v-col :class="col2Classes">
        <double-image-image :image="blok.images[1].image" :fit="blok.image_fit" height="100%" />
        <p v-if="blok.images[1].image.title" class="d-block d-md-none mt-5 pl-1 pr-1"><span class="copyright">{{ blok.images[1].image.title }}</span><span class="credit">{{ blok.images[1].image.source }}</span></p>
        <p v-else-if="blok.images[0].image.title" class="d-block d-md-none mt-5 pl-1 pr-1"><span class="copyright">{{ blok.images[0].image.title }}</span><span class="credit">{{ blok.images[0].image.source }}</span></p>
      </v-col>
    </v-row>
    <v-row v-if="blok.images[0].image.title && blok.images[1].image.title" class="mt-0 d-none d-md-flex">
      <v-col :class="captionCol1Classes">
        <p class="mt-2"><span v-if="blok.images[0].image.title" class="copyright">{{ blok.images[0].image.title }}</span><span class="credit">{{ blok.images[0].image.source }}</span></p>
      </v-col>
      <v-col :class="captionCol2Classes">
        <p class="mt-2"><span v-if="blok.images[1].image.title" class="copyright">{{ blok.images[1].image.title }}</span><span class="credit">{{ blok.images[1].image.source }}</span></p>
      </v-col>
    </v-row>
    <v-row v-else class="mt-0 d-none d-md-flex">
      <v-col :class="captionSingleClasses">
        <p v-if="blok.images[0].image.title || blok.images[0].image.source" class="mt-2"><span v-if="blok.images[0].image.title" class="copyright">{{ blok.images[0].image.title }}</span> <span class="credit">{{ blok.images[0].image.source }}</span></p>
        <p v-else class="mt-2"><span v-if="blok.images[1].image.title" class="copyright">{{ blok.images[1].image.title }}</span><span class="credit">{{ blok.images[1].image.source }}</span></p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
const props = defineProps({ blok: Object })

import DoubleImageImage from "@/components/DoubleImageImage.vue";
import {computed} from "vue";

const col1Classes = computed(() => {
  if (props.blok.width === 'full') {
    return 'v-col-12 v-col-md-6 offset-0 d-flex flex-column justify-' + props.blok.justify
  }
  else if (props.blok.width === 'aligned') {
    return 'v-col-12 offset-0 v-col-sm-5 offset-sm-1 v-col-md-4 offset-md-2 v-col-lg-3 offset-lg-3 d-flex flex-column justify-' + props.blok.justify
  }
  else if (props.blok.width === 'inset') {
    return 'v-col-12 offset-0 v-col-sm-4 offset-sm-2 v-col-md-3 offset-md-3 v-col-lg-2 offset-lg-4 d-flex flex-column justify-' + props.blok.justify
  }
  else {
    return 'v-col-12 offset-0 v-col-md-5 offset-md-1 v-col-lg-4 offset-lg-2 d-flex flex-column justify-' + props.blok.justify
  }
})

const col2Classes = computed(() => {
  if (props.blok.width === 'full') {
    return 'v-col-12 v-col-md-6 offset-0 d-flex flex-column justify-' + props.blok.justify
  }
  else if (props.blok.width === 'aligned') {
    return 'v-col-12 offset-0 v-col-sm-5 v-col-md-4 v-col-lg-3 d-flex flex-column justify-' + props.blok.justify
  }
  else if (props.blok.width === 'inset') {
    return 'v-col-12 v-col-sm-4 v-col-md-3 v-col-lg-2 d-flex flex-column justify-' + props.blok.justify
  }
  else {
    return 'v-col-12 offset-0 v-col-md-5 v-col-lg-4 d-flex flex-column justify-' + props.blok.justify
  }
})

const captionCol1Classes = computed(() => {
  if (props.blok.width === 'full') {
    return 'v-col-12 v-col-md-6 offset-0'
  }
  else if (props.blok.width === 'aligned') {
    return 'v-col-12 offset-0 v-col-sm-5 offset-sm-1 v-col-md-4 offset-md-2 v-col-lg-3 offset-lg-3'
  }
  else if (props.blok.width === 'inset') {
    return 'v-col-12 offset-0 v-col-sm-4 offset-sm-2 v-col-md-3 offset-md-3 v-col-lg-2 offset-lg-4'
  }
  else {
    return 'v-col-12 offset-0 v-col-md-5 offset-md-1 v-col-lg-4 offset-lg-2'
  }
})

const captionCol2Classes = computed(() => {
  if (props.blok.width === 'full') {
    return 'v-col-12 v-col-md-6 offset-0'
  }
  else if (props.blok.width === 'aligned') {
    return 'v-col-12 offset-0 v-col-sm-5 v-col-md-4 v-col-lg-3'
  }
  else if (props.blok.width === 'inset') {
    return 'v-col-12 v-col-sm-4 v-col-md-3 v-col-lg-2'
  }
  else {
    return 'v-col-12 offset-0 v-col-md-5 v-col-lg-4'
  }
})

const captionSingleClasses = computed(() => {
  if (props.blok.width === 'full') {
    return 'v-col-12 offset-0 v-col-sm-10 offset-sm-1 v-col-md-8 offset-md-2 v-col-lg-6 offset-lg-3 mt-0 pb-0'
  }
  else if (props.blok.width === 'aligned') {
    return 'v-col-12 offset-0 v-col-sm-10 offset-sm-1 v-col-md-8 offset-md-2 v-col-lg-6 offset-lg-3'
  }
  else if (props.blok.width === 'inset') {
    return 'v-col-12 offset-0 v-col-sm-8 offset-sm-2 v-col-md-6 offset-md-3 v-col-lg-4 offset-lg-4'
  }
  else {
    return 'v-col-12 offset-0 v-col-md-10 offset-md-1 v-col-lg-8 offset-lg-2'
  }
})

const rowClasses = computed(() => {
  if (props.blok.width === 'full') {
    return 'd-flex flex-row justify-space-around'
  }
  else {
    return ''
  }
})

</script>

<style scoped>

</style>
