<template>
  <v-container fluid class="pb-0">
    <v-row v-if="blok.text">
      <v-col v-if="opEd && index === 0" class="v-col-10 offset-1 v-col-sm-8 offset-sm-2 v-col-md-6 offset-md-3 v-col-lg-3 offset-lg-0">

          <v-row>
            <v-col class="v-col-12 offset-0 v-col-lg-11 offset-lg-1 v-col-xl-9 offset-xl-3 pt-0">
              <div style="border: solid 1px black; border-top: 0;" class="pl-3 pr-3 pb-3 pl-sm-4 pr-sm-4 pb-sm-4 mt-6"><v-row>
                  <v-col class="v-col-5 offset-0 v-col-lg-12 offset-lg-0 pt-0">
                    <v-img
                        v-if="person"
                        aspect-ratio="2/3"
                        class="person"
                        :srcset="person.content.profile_picture.filename + '/m/300x450 300w,' + person.content.profile_picture.filename + '/m/480x720 480w,' + person.content.profile_picture.filename + '/m/640x960 640w,' + person.content.profile_picture.filename + '/m/960x1440 960w,'  + person.content.profile_picture.filename + '/m/1280x1920 1280w'"
                        sizes="(max-width: 600px) 300px, (min-width: 600px) 480px, (min-width: 960px) 640px, (min-width: 1280px) 960px, 1280px"
                        :src="person.content.profile_picture.filename + '/m/300x450'"
                        :alt="person.name"
                        cover
                    />
                  </v-col>
                  <v-col class="v-col-7 v-col-lg-12 pl-0 pl-sm-2 pr-3 pl-lg-3 pt-0 pt-lg-3 pr-sm-3">
                    <div class="d-flex flex-column align-content-end justify-end justify-sm-start justify-md-start fill-height">
                      <h3 class="author mt-0 mb-3 mb-sm-6 mb-md-6">{{person.name}}</h3>
                      <v-divider class="mt-0 mb-sm-5 name-divider" style="opacity: 1;"></v-divider>
                      <p class="biog d-none d-sm-block mb-0">{{ person.content.description }}</p>
                    </div>
                  </v-col>
                  <v-col class="d-flex d-sm-none pt-0">
                    <p class="biog mb-0">{{ person.content.description }}</p>
                  </v-col>
                </v-row>
              </div>
            </v-col>

          </v-row>


<!--        <v-row>-->
<!--          <v-col class="v-col-12 v-col-sm-10 offset-sm-1 v-col-lg-12 offset-lg-0">-->
<!--            <v-divider class="mt-0 mb-6 mr-lg-12 mr-xl-16 ml-lg-12 ml-xl-16"></v-divider>-->
<!--          </v-col>-->
<!--        </v-row>-->
      </v-col>
      <v-col v-if="opEd && index === 0" class="v-col-12 offset-0 v-col-sm-10 offset-sm-1 v-col-lg-6 offset-lg-0 mt-0 pb-0">
        <div class="body-text" v-html="articleContent"></div>
      </v-col>
      <v-col v-else class="v-col-12 offset-0 v-col-sm-10 offset-sm-1 v-col-md-8 offset-md-2 v-col-lg-6 offset-lg-3 mt-0 pb-0">
        <div :class="'body-text' + (index === 0 ? ' drop-cap' : '')" v-html="articleContent"></div>
      </v-col>
<!--      <v-col class="d-none d-lg-block v-col-md-3">-->
<!--        <Adsense-->
<!--            :data-ad-client="adsenseClientId"-->
<!--            :data-ad-slot="adsenseAdSlotId"-->
<!--        >-->
<!--        </Adsense>-->
<!--        <iframe src="https://cdn.thebannermen.com/wp-content/uploads/_banners/standard_Gatorade_Flow/Athletes/Athletes_blocksm_website/index.html" style="border: 0" />-->
<!--        <v-img :src="smallAd" />-->
<!--      </v-col>-->
    </v-row>
  </v-container>
</template>

<script setup>
import router from "@/router";

const props = defineProps({ blok: Object, index: Number, opEd: Boolean, person: Object })

// import smallAd from '@/assets/placeholders/small-ad.png'

import { computed } from "vue";
import { renderRichText } from "@storyblok/vue";
console.log(props.blok.text.content[0].content)
const articleContent = computed(() => renderRichText(props.blok.text));
console.log(articleContent)

const adsenseClientId = () => {
  return import.meta.env.VITE_ADSENSE_CLIENT_ID
}

const adsenseAdSlotId = () => {
  return import.meta.env.VITE_ADSENSE_AD_SLOT_ID
}
</script>

<style scoped>
  h2 {
    margin-bottom: 0.1em;
  }
  h3 {
    margin-bottom: 0.1em;
  }
  p {
    margin-bottom: 1em;
    line-height: 1.2em;
  }
  .biog-container {

    @media only screen and (min-width: 1500px) {
      margin-left: 20px;
    }
  }
  .name-divider {
    margin-right: 10%;
    @media only screen and (min-width: 600px) {
      margin-right: 20%;
    }
  }
  .biog {
    font-family: "GroteskLight", sans-serif !important;
    font-weight: normal;
    @media only screen and (max-width: 599px) {
      font-size: 1.1em;
      line-height: 1.4em;
    }
    @media only screen and (min-width: 600px) {
      font-size: 1.3em;
      line-height: 1.5em;
    }
  }
  .person {
    @media only screen and (min-width: 1280px) {
      margin-right: 40%;
    }
  }
</style>
