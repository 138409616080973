import { defineStore } from 'pinia'
import {inject} from "vue";
import axios from "axios";
import router from "@/router";
import { version } from '../../package.json'

export const useIndexStore = defineStore('index', {
    state: () => ({
        firstPageLoad: true,
        release: import.meta.env.VITE_RELEASE_VERSION
    }),
    actions: {
        newPage() {
            this.firstPageLoad = false;
        },
        setRelease (version) {
            this.release = version
        }
    }
})
