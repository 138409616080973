<template>

    <div v-if="props.blok.width === 'full'" :class="props.blok.hide_on_mobile ? 'd-none d-sm-block' : ''">
      <v-img
          :srcset="blok.image.filename + '/m/1280x0 1280w,' + blok.image.filename + '/m/1920x0 1920w,' + blok.image.filename + '/m/2560x0 2560w'"
          sizes="(min-width: 960px) 1280px, (min-width: 1280px) 1920px, 2560px"
          :src="blok.image.filename + '/m/960x0'"
          lazy-src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
          cover
          :alt="blok.image.alt"
          class="mt-6"
      >
      </v-img>
      <v-col v-if="blok.image.title || blok.image.source" class="v-col-12 offset-0 v-col-sm-10 offset-sm-1 v-col-md-8 offset-md-2 v-col-lg-6 offset-lg-3 mt-0 pb-4">
        <p class="mt-2"><span v-if="blok.image.title" class="copyright">{{ blok.image.title }}</span><span class="credit">{{ blok.image.source }}</span></p>
      </v-col>
    </div>



  <v-container v-else :fluid="true" :class="props.blok.hide_on_mobile ? 'd-none d-sm-block' : ''">
  <v-row>
    <v-col :class="colClasses">
      <v-img
          :srcset="blok.image.filename + '/m/1280x0 1280w,' + blok.image.filename + '/m/1920x0 1920w,' + blok.image.filename + '/m/2560x0 2560w'"
          sizes="(min-width: 960px) 1280px, (min-width: 1280px) 1920px, 2560px"
          :src="blok.image.filename + '/m/960x0'"
          lazy-src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
          :alt="blok.image.alt"
      >
      </v-img>
      <p class="mt-5" v-if="blok.image.title || blok.image.source"><span v-if="blok.image.title" class="copyright">{{ blok.image.title }}</span><span class="credit">{{ blok.image.source }}</span></p>
    </v-col>
  </v-row>
  </v-container>
</template>

<script setup>
import {computed} from "vue";

const props = defineProps({ blok: Object })

// v-col-12 offset-0 v-col-sm-10 offset-sm-1 v-col-md-8 offset-md-2

const colClasses = computed(() => {
  if (props.blok.width === 'aligned') {
    return 'v-col-12 offset-0 v-col-sm-10 offset-sm-1 v-col-md-8 offset-md-2 v-col-lg-6 offset-lg-3'
  }
  else if (props.blok.width === 'inset') {
    return 'v-col-12 offset-0 v-col-sm-8 offset-sm-2 v-col-md-6 offset-md-3 v-col-lg-4 offset-lg-4'
  }
  else {
    return 'v-col-12 offset-0 v-col-sm-10 offset-sm-1 v-col-md-8 offset-md-2'
  }
})

</script>

<style scoped>
/*.v-container--fluid {*/
/*  padding: 0 !important;*/
/*}*/
</style>
