//import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import '@/styles/main.scss'
import { createVuetify } from 'vuetify'
// import colors from 'vuetify/lib/util/colors'
import '@fortawesome/fontawesome-free/css/all.css' // Ensure your project is capable of handling css files
import { aliases, fa } from 'vuetify/iconsets/fa'

export default createVuetify({
    icons: {
        defaultSet: 'fa', // This is already the default value - only for display purposes
        aliases,
        sets: {
            fa
        },
    },
    customProperties: true,
    theme: {
        defaultTheme: 'light',
        themes: {
            light: {
                dark: false,
                colors: {
                    link: '#34498e',
                    primary: '#DFFF4F',
                    secondary: '#e7ebed',
                    tertiary: '#eeeeee',
                    surface: '#1E1E1E',
                    'surface-bright': '#474747',
                    error: '#b71c1c',
                    background: '#F1ECE5',
                    business: '#06C2AC',
                    finance: '#DE2053',
                    culture: '#DFFF4F',
                    businessText: '#000',
                    financeText: '#FFF',
                    cultureText: '#000',
                    businessHighlight: '#008172',
                    financeHighlight: '#dc5378',
                    cultureHighlight: '#C2E03B',
                    tag: '#bdbdbd',
                    altButton: '#4F4F4F',
                    'on-altButton': 'primary',
                    darkButton: '#000000',
                    'on-darkButton': 'primary',
                    podcasts: '#272D4D',
                    podcastsBody: '#12152A',
                    podcastsSub: '#272D4D'
                }
            },
        },
    },
})
