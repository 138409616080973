<template>
  <v-container fluid :class="'vertical-recent vertical-' + vertical">
    <div v-editable="blok">
      <v-container fluid class="pa-3">
        <v-row>
          <v-col class="d-block d-sm-none v-col-12 pa-0 pb-6">
            <h2 v-if="vertical" class="text-lg">Future of<br><span>{{vertical}}</span></h2>
            <h2 v-else class="text-lg">Trending<br/><span>Stories</span></h2>
            <div v-if="vertical" class="view-more mb-6"><router-link :to="{ name: 'future-of', params: {slug: vertical}}">View more</router-link></div>
          </v-col>
          <v-col class="v-col-12 offset-0 v-col-sm-6 offset-sm-1 mb-2">
            <v-row>
              <v-col class="v-col-12 v-col-lg-6 pa-0 mb-6" v-if="blok.length > 0" v-for="feature in blok" :key="feature._uid">

                  <v-card
                      elevation="0"
                      class="recent-story"
                      color="transparent"
                  >
                    <div class="aspect-ratio-box-16x9">
                      <router-link :to="{ name: 'article', params: {slug: feature.slug}}">
                        <v-img
                            v-if="feature.content.header[0].listing_image && feature.content.header[0].listing_image.filename"
                            :src="feature.content.header[0].listing_image.filename + '/m/700x394/filters:focal('+ feature.content.header[0].listing_image.focus +')'"
                            class="align-end mt-4"
                            aspect-ratio="16/9"
                            :eager="true"
                            contain
                            :alt="feature.content.header[0].listing_image.alt"
                        >
                        </v-img>
                        <v-img
                            v-else-if="feature.content.header[0].background_image"
                            :src="feature.content.header[0].background_image.filename + '/m/700x394/filters:focal('+ feature.content.header[0].background_image.focus +')'"
                            class="align-end mt-4"
                            aspect-ratio="16/9"
                            :eager="true"
                            contain
                            :alt="feature.content.header[0].background_image.alt"
                        >
                        </v-img>
                        <h3 class="pr-8">{{ feature.name }}</h3>
                      </router-link>
                    </div>

                    <v-card-text class="pa-0 pr-8 mr-0 reporter">
                      <authors :reporters="feature.content.reporters" :opEd="feature.content.op_ed" />
                      <VerticalTag v-if="vertical && feature.content.vertical" :vertical="feature.content.vertical" :opEd="feature.content.op_ed"/>
                      <v-btn v-else-if="feature.content.vertical" variant="flat" density="compact" color="#333" class="tag text-uppercase" @click="goTo(feature.content.vertical)"><span v-if="feature.content.op_ed">PERSPECTIVES</span><span v-else>FUTURE OF {{ feature.content.vertical }}</span></v-btn>
                      <p class="standfirst">{{feature.content.header[0].standfirst}}</p>
                    </v-card-text>

                  </v-card>

              </v-col>
            </v-row>
          </v-col>
          <v-col class="d-none d-sm-block v-col-3 offset-1">
            <h2 v-if="vertical" class="text-lg">Future of<br/><span>{{vertical}}</span></h2>
            <h2 v-else class="text-lg">Trending<br/><span>Stories</span></h2>
            <div v-if="vertical" class="view-more"><router-link :to="{ name: 'future-of', params: {slug: vertical}}">View more</router-link></div>
          </v-col>

        </v-row>
      </v-container>

    </div>
  </v-container>
</template>

<script setup>
import router from "@/router";

defineProps({ blok: Object, vertical: String })

import VerticalTag from "@/components/VerticalTag.vue"
import Authors from "@/components/Authors.vue";

// import { computed } from 'vue'
//
// const background = computed(() => {
//   return var(--v-theme-business))
// })

const goTo = (vertical) => {
  router.push({name: 'future-of', params: {slug: vertical}})
}

</script>

<style scoped lang="scss">

.vertical-recent {
  padding-top: 70px;
  padding-bottom: 60px;
}
.view-more {
  font-family: "GroteskScreen", sans-serif !important;
}

@media only screen and (max-width: 599px) {
  .vertical-recent {
    padding-top: 30px;
    padding-bottom: 20px;
  }
}

.vertical-business {
  background-color: rgb(var(--v-theme-business));
}
.vertical-finance {
  background-color: rgb(var(--v-theme-finance));
}
.vertical-culture {
  background-color: rgb(var(--v-theme-culture));
}
.vertical-undefined {
  background-color: black;
}

.vertical-finance, .vertical-undefined {
  color: white;
}
.vertical-culture, .vertical-business {
  color: black;
}

.recent-story .reporter {
  font-family: "GroteskMonoRegular", serif !important;
  font-weight: normal;
  margin-right: 10px;
}

.recent-story .standfirst {
  font-family: "MagrebRegular", serif !important;
  font-weight: normal;
  font-size: 1.177em;
  margin-top: 12px;
}

h2 {
  font-family: "GroteskLight", serif !important;
  font-size: 3rem;
  line-height: 1em;
  font-weight: normal;
  @media only screen and (max-width: 599px) {
    font-size: 2.5rem;
  }
}

h2 span {
  font-family: "GroteskBold", serif !important;
  font-weight: bold;
  text-transform: capitalize;
}
</style>
