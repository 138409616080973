<template>
  <v-row class="lead-photo">
    <v-col class="v-col-12 v-col-lg-6 pt-0 pr-0 pb-0">
      <v-img
          aspect-ratio="1/1"
          lazy-src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
          :srcset="blok.background_image.filename + '/m/300x300/filters:focal('+ blok.background_image.focus +'):brightness(' + blok.background_brightness.value + '):blur(' + blok.background_blur.value + ') 300w,' + blok.background_image.filename + '/m/480x480/filters:focal('+ blok.background_image.focus +'):brightness(' + blok.background_brightness.value + '):blur(' + blok.background_blur.value + ') 480w,' + blok.background_image.filename + '/m/640x640/filters:focal('+ blok.background_image.focus +'):brightness(' + blok.background_brightness.value + '):blur(' + blok.background_blur.value + ') 640w,' + blok.background_image.filename + '/m/960x960/filters:focal('+ blok.background_image.focus +'):brightness(' + blok.background_brightness.value + '):blur(' + blok.background_blur.value + ') 960w,'  + blok.background_image.filename + '/m/1280x1280/filters:focal('+ blok.background_image.focus +'):brightness(' + blok.background_brightness.value + '):blur(' + blok.background_blur.value + ') 1280w'"
          sizes="(max-width: 600px) 300px, (min-width: 600px) 480px, (min-width: 960px) 640px, (min-width: 1280px) 960px, 1280px"
          :src="blok.background_image.filename + '/m/300x300/filters:focal('+ blok.background_image.focus +')'"
          :alt="blok.background_image.alt"
      ></v-img>
    </v-col>
    <v-col class="v-col-12 v-col-sm-10 offset-sm-1 v-col-lg-6 offset-lg-0 mt-11 mt-sm-14 mt-md-15 mt-lg-0 d-flex flex-column justify-center lead-photo-text">
      <h1>{{ name }}</h1>
      <div class="lead-caption">
        <authors :reporters="reporters" :opEd="opEd" />
        <VerticalTag v-if="vertical" :vertical="vertical" :opEd="opEd"/>
        <br v-if="vertical" class="d-block d-sm-none">
        <span class="date">{{ moment(first_published_at).format("MMMM D YYYY") }}</span><br>
        <span class="credit" v-if="blok.background_image.source">{{ blok.background_image.source }}</span>
      </div>
      <p class="standfirst mb-6 mb-lg-0">{{ blok.standfirst }}</p>
    </v-col>
  </v-row>
</template>

<script setup>
defineProps({ blok: Object, reporters: Object, first_published_at: String, vertical: String, tags: Array, name: String, opEd: Boolean })

import VerticalTag from "@/components/VerticalTag.vue"
import Authors from "@/components/Authors.vue";
import moment from "moment"

import { useTheme } from "vuetify";
const theme = useTheme()

const tagColour = (vertical) => {
  if (vertical === 'finance') {
    return theme.global.current.value.colors.financeHighlight
  }
  else if (vertical === 'culture') {
    return theme.global.current.value.colors.cultureHighlight
  }
  else if (vertical === 'business') {
    return theme.global.current.value.colors.businessHighlight
  }
}

</script>

<style scoped lang="scss">

.vertical-business {
  .lead-photo {
    background-color: rgb(var(--v-theme-business));
  }
  h1, h2, h3, p, a, .date, .credit {
    color: rgb(var(--v-theme-businessText));
  }
}
.vertical-finance {
  .lead-photo {
    background-color: rgb(var(--v-theme-finance));
  }
  h1, h2, h3, p, a, .date, .credit {
    color: rgb(var(--v-theme-financeText));
  }
}
.vertical-culture {
  .lead-photo {
    background-color: rgb(var(--v-theme-culture));
  }
  h1, h2, h3, p, a, .date, .credit {
    color: rgb(var(--v-theme-cultureText));
  }
}

.lead-photo {
  margin-top: 0;
}

.standfirst {
  font-family: "GroteskScreen", serif !important;
  padding-bottom: 0;
  line-height: 1.1em;
  margin-top: 30px;
}

h1 {
  font-family: "GroteskRegular", serif !important;
  font-weight: normal;
  line-height: 1em;
  margin-bottom: 26px;
}
h2 {
  font-family: "GroteskLight", serif !important;
  font-weight: normal;
  line-height: 1.1em;
  margin-bottom: 12px;
}

@media only screen and (max-width: 599px) {
  h1 {
    font-size: 2.1em;
    line-height: 1.2em;
  }
  h2 {
    font-size: 2em;
  }
  .standfirst {
    font-size: 1.5em;
    line-height: 1.3em;
    padding-bottom: 23px;
  }
  .lead-caption {
    margin-top: 10px
  }
  .lead-photo-text {
    padding: 0 24px;
  }
}

@media only screen and (min-width: 600px) {
  h1 {
    font-size: 3em;
    line-height: 1.1em;
  }
  h2 {
    font-size: 2em;
  }
  .standfirst {
    font-size: 2em;
    line-height: 1.2em;
    padding-bottom: 23px;
  }
  .lead-photo-text {
    padding: 0 24px;
  }
}

@media only screen and (min-width: 960px) {
  h1 {
    font-size: 3em;
    line-height: 1em;
  }
  h2 {
    font-size: 2em;
  }
  .lead-photo-text {
    padding: 0 80px;
  }
}

@media only screen and (min-width: 1280px) {
  h1 {
    font-size: 4em;
  }
  h2 {
    font-size: 2em;
  }
}

@media only screen and (min-width: 1920px) {
  h1 {
    font-size: 6em;
  }
  h2 {
    font-size: 2em;
  }
}
</style>
