<template>
  <v-btn variant="flat" density="compact" :color="tagColour(vertical)" class="tag text-uppercase" @click="goTo(vertical)"><span v-if="opEd">PERSPECTIVES</span><span v-else>FUTURE OF {{ vertical }}</span></v-btn>
</template>

<script setup>

import router from "@/router";

const props = defineProps({ vertical: String, opEd: Boolean })

import { useTheme } from "vuetify";
const theme = useTheme()

const tagColour = (vertical) => {
  if (vertical === 'finance') {
    return theme.global.current.value.colors.financeHighlight
  }
  else if (vertical === 'culture') {
    return theme.global.current.value.colors.cultureHighlight
  }
  else if (vertical === 'business') {
    return theme.global.current.value.colors.businessHighlight
  }
}

const goTo = (vertical) => {
  router.push({name: 'future-of', params: {slug: vertical}})
}
</script>

<style scoped>

</style>
