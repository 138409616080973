import axios from "axios";
import {useAccountStore} from "@/stores/account";

export const accountService = {
    logout
};

function logout() {

    let accountStore = useAccountStore()

    useAccountStore().logout()

    let apiClient = axios.create({
        baseURL: import.meta.env.VITE_API_HOST,
        withCredentials: true,
        withXSRFToken: true,
    });

    return apiClient.get('/sanctum/csrf-cookie').then(() => {
        return apiClient
            .post('/logout')
            .then(async response => {
                console.log(response.status)
                // logout successful
                if (response.status === 204) {
                    return Promise.resolve()
                }
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    })
}
